import React from "react"
import styled from "@emotion/styled"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import Layout from "../components/global/layout"
import ButtonLink from "../components/form/ButtonLink"
import { urls } from "../data/urls"

const Title = styled.h1`
  font-family: "Alt Goth", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.wine};
  font-size: 1.8rem;
  @media (max-width: 350px) {
    font-size: 2rem;
  }
`
const SocialGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 16px;
`
const Scroll = styled.div`
  overflow: auto;
  height: 100%;
`

const Grid = styled.div`
  display: grid;
  min-height: 100%;
  grid-template-rows: 1fr min-content;
  padding: 0 32px 32px 32px;
  align-items: center;
`
const Content = styled.div`
  max-width: 400px;
  margin: 0 auto 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: ${props => props.theme.wine};
  }
`
const overrideStyle = {
  fill: "#BF1F47",
}

export default function Share() {
  return (
    <Layout>
      <Scroll>
        <Grid>
          <Content>
            <Title>
              Are you now at one with your vulva and ready to show it some love?
              We hope so!
            </Title>
            <p>Share Love Your Vulva so that everyone can get involved:</p>
            <SocialGrid>
              <FacebookShareButton url="https://love-your-vulva.brook.org.uk/">
                <FacebookIcon size={40} round={true} bgStyle={overrideStyle} />
              </FacebookShareButton>
              <LinkedinShareButton url="https://love-your-vulva.brook.org.uk/">
                <LinkedinIcon size={40} round={true} bgStyle={overrideStyle} />
              </LinkedinShareButton>
              <TwitterShareButton url="https://love-your-vulva.brook.org.uk/">
                <TwitterIcon size={40} round={true} bgStyle={overrideStyle} />
              </TwitterShareButton>
            </SocialGrid>
            <p>
              Please visit Brook for more information on{" "}
              <a href="https://www.brook.org.uk/topics/vaginas-and-vulvas/">
                vulvas and vaginas.
              </a>{" "}
            </p>
          </Content>
          <ButtonLink
            backgroundColor={props => props.theme.rose}
            buttonStyle={"inverted"}
            to={urls.INTRO_END}
          >
            Main Menu
          </ButtonLink>
        </Grid>
      </Scroll>
    </Layout>
  )
}
