import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Container = styled(AniLink)`
  width: 100%;
  border-radius: ${props => props.theme.radius.xl};
  background-color: ${props => props.theme.shade[100]};
  padding: 12px 32px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 600;

  background-color: ${props =>
    props.buttonStyle === "ghost"
      ? "transparent"
      : props.buttonStyle === "inverted"
      ? props.theme.shade[100]
      : props.backgroundColor};
  color: ${props =>
    props.buttonStyle === "ghost"
      ? props.backgroundColor
      : props.buttonStyle === "inverted"
      ? props.backgroundColor
      : props.theme.shade[100]};
  border: solid 1px
    ${props =>
      props.buttonStyle === "ghost"
        ? props.backgroundColor
        : props.buttonStyle === "inverted"
        ? "transparent"
        : "transparent"};
`

export default function ButtonLink({
  onClick,
  children,
  buttonStyle,
  backgroundColor,
  to,
}) {
  return (
    <Container
      buttonStyle={buttonStyle}
      backgroundColor={backgroundColor}
      onClick={onClick}
      to={to}
      cover
      bg="#FF6673"
    >
      {children}
    </Container>
  )
}

ButtonLink.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  buttonStyle: PropTypes.any,
  backgroundColor: PropTypes.any,
}
